import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';

import { Classname } from 'utils/types';
import { RichText } from 'components/Prismic';

interface IPrimaryContent extends Classname {
  title: RichTextBlock[];
  panelTexts: RichTextBlock[];
}

const Container = styled.div`
  display: grid;
  row-gap: 8.125rem;
  align-content: flex-end;
  justify-content: center;
  justify-items: center;
  z-index: 1;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > h2 {
      text-align: center;
      line-height: 1.04;
      letter-spacing: -0.1em;
      color: ${colors.backgroundDark};
      font-size: 10.625rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const PanelTexts = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > ul {
      display: grid;
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
      column-gap: 14rem;
      border-radius: 3rem;
      background-color: #8c817714;
      padding: 7.5rem 11.125rem 8.5rem;
      backdrop-filter: blur(1.5625rem);

      & > li {
        list-style: none;
        line-height: 1.09;
        color: ${colors.black}f5;
        font-size: 3.25rem;

        ${tp.useFont(fonts => fonts.Switzer.light)};
      }
    }
  `}
`;

const PrimaryContent = forwardRef<HTMLDivElement, IPrimaryContent>(function PrimaryContent(
  { className, title, panelTexts },
  forwardedRef
) {
  return (
    <Container className={className} ref={forwardedRef}>
      <Title render={title} />
      <PanelTexts render={panelTexts} />
    </Container>
  );
});

export const desktopSalonsSpaPrimaryContentFragment = graphql`
  fragment IndustryDesktopSalonsSpaPrimaryContentData on PrismicIndustryDataDesktopIndustrySalonsSpa {
    primary {
      industry_salons_spa_primary_title {
        richText
      }
      industry_salons_spa_primary_panel_texts {
        richText
      }
    }
  }
`;

export default PrimaryContent;
