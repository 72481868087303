import { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';

import { TRestaurantsSection } from './RestaurantsSection';

import { richTextWithPlaceholder } from 'utils/prismic';

const useRestaurantsAnimation = () => {
  const backgroundContainerRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const context = gsap.context(() => {
      gsap.fromTo(
        backgroundRef.current,
        {
          top: '0%',
        },
        {
          scrollTrigger: {
            trigger: backgroundContainerRef.current,
            start: 'top top',
            end: `bottom bottom`,
            scrub: true,
          },
          top: '-40%',
        }
      );
    });

    return () => context.revert();
  }, []);

  return { backgroundContainerRef, backgroundRef };
};

export const useRestaurantsData = ({ primary }: TRestaurantsSection) => {
  const {
    industry_restaurants_background_image: backgroundImage,
    industry_restaurants_primary_title: primaryTitle,
    industry_restaurants_primary_card_texts: primaryCardTexts,
    industry_restaurants_secondary_title: secondaryTitle,
    industry_restaurants_secondary_description: secondaryDescription,
    industry_restaurants_tertiary_title: tertiaryTitle,
    industry_restaurants_tertiary_card_texts: tertiaryCardTexts,
    industry_restaurants_quarternary_title: quarternaryTitle,
    industry_restaurants_quarternary_panel_texts: quarternaryPanelTexts,
    industry_restaurants_quinary_title: quinaryTitle,
    industry_restaurants_quinary_description: quinaryDescription,
  } = primary;

  const { gatsbyImageData, alt } = backgroundImage;

  return {
    backgroundImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
    primaryTitle: richTextWithPlaceholder(primaryTitle, ''),
    primaryCardTexts: richTextWithPlaceholder(primaryCardTexts, ''),
    secondaryTitle: richTextWithPlaceholder(secondaryTitle, ''),
    secondaryDescription: richTextWithPlaceholder(secondaryDescription, ''),
    tertiaryTitle: richTextWithPlaceholder(tertiaryTitle, ''),
    tertiaryCardTexts: richTextWithPlaceholder(tertiaryCardTexts, ''),
    quarternaryTitle: richTextWithPlaceholder(quarternaryTitle, ''),
    quarternaryPanelTexts: richTextWithPlaceholder(quarternaryPanelTexts, ''),
    quinaryTitle: richTextWithPlaceholder(quinaryTitle, ''),
    quinaryDescription: richTextWithPlaceholder(quinaryDescription, ''),
    ...useRestaurantsAnimation(),
  };
};
