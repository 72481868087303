import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';

import { RichText } from 'components/Prismic';
import { useFadeIn } from 'utils/animations/useFadeIn';

interface ITertiaryContent {
  title: RichTextBlock[];
  cardTexts: RichTextBlock[];
}

const Container = styled.div`
  display: grid;
  align-items: flex-start;
  justify-items: center;
  background-color: #bdbdbd;
  padding-top: 26.5rem;
  height: 139.625rem;
`;

const Content = styled.div`
  display: grid;
  row-gap: 6.875rem;
  align-content: flex-start;
  justify-items: center;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    &::after {
      display: block;
      margin-top: 5rem;
      background-color: ${colors.black};
      width: 11.5rem;
      height: 0.25rem;
      content: '';
    }

    & > h2 {
      text-align: center;
      line-height: 0.965;
      letter-spacing: -0.04em;
      color: ${colors.black};
      font-size: 9rem;

      ${tp.useFont(fonts => fonts.Switzer.lightest)};

      strong {
        ${tp.useFont(fonts => fonts.Switzer.regular)};
      }
    }
  `}
`;

const CardTexts = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > ul {
      display: grid;
      grid-template-rows: auto auto;
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
      row-gap: 2.75rem;
      column-gap: 7.875rem;
      text-align: center;
      line-height: 1.09;
      color: ${colors.black}f5;
      font-size: 3.25rem;

      ${tp.useFont(fonts => fonts.Switzer.lightest)};

      & em {
        line-height: 1.04;
        letter-spacing: -0.1em;
        color: ${colors.white}e6;
        font-size: 4rem;
        font-style: normal;

        ${tp.useFont(fonts => fonts.Switzer.light)};
      }

      & > li {
        display: contents;
      }
    }
  `}
`;

const TertiaryContent: React.FC<ITertiaryContent> = ({ title, cardTexts }) => {
  const contentRef = useFadeIn();

  return (
    <Container>
      <Content ref={contentRef}>
        <Title render={title} />
        <CardTexts render={cardTexts} />
      </Content>
    </Container>
  );
};

export const desktopHotelsTertiaryContentFragment = graphql`
  fragment IndustryDesktopHotelsTertiaryContentData on PrismicIndustryDataDesktopIndustryHotels {
    primary {
      industry_hotels_tertiary_title {
        richText
      }
      industry_hotels_tertiary_card_texts {
        richText
      }
    }
  }
`;

export default TertiaryContent;
