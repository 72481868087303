import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';

import { FadeIn } from 'animations/FadeIn';
import { RichText } from 'components/Prismic';
import { Classname } from 'utils/types';

interface IQuarternaryContent extends Classname {
  title: RichTextBlock[];
  panelTexts: RichTextBlock[];
}

const Container = styled(FadeIn)`
  display: grid;
  row-gap: 11.625rem;
  justify-items: center;
  justify-self: center;
  z-index: 1;
  border-radius: 3rem;
  background-color: #8c817780;
  padding: 9.25rem 5.375rem 7.75rem;
  backdrop-filter: blur(1.5625rem);
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > h2 {
      text-align: center;
      line-height: 0.965;
      letter-spacing: -0.04em;
      color: ${colors.primary};
      font-size: 8rem;

      ${tp.useFont(fonts => fonts.Switzer.lightest)};

      strong {
        ${tp.useFont(fonts => fonts.Switzer.regular)};
      }
    }
  `}
`;

const PanelTexts = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > ul {
      display: grid;
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
      column-gap: 17.125rem;

      & > li {
        list-style: none;
        line-height: 1.09;
        color: ${colors.white};
        font-size: 3.25rem;

        ${tp.useFont(fonts => fonts.Switzer.lightest)};

        strong {
          ${tp.useFont(fonts => fonts.Switzer.lightBold)};
        }
      }
    }
  `}
`;

const QuarternaryContent: React.FC<IQuarternaryContent> = ({ className, title, panelTexts }) => {
  return (
    <Container className={className}>
      <Title render={title} />
      <PanelTexts render={panelTexts} />
    </Container>
  );
};

export const desktopRestaurantsQuarternaryContentFragment = graphql`
  fragment IndustryDesktopRestaurantsQuarternaryContentData on PrismicIndustryDataDesktopIndustryRestaurants {
    primary {
      industry_restaurants_quarternary_title {
        richText
      }
      industry_restaurants_quarternary_panel_texts {
        richText
      }
    }
  }
`;

export default QuarternaryContent;
