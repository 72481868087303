import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useHotelsData } from './hooks';
import PrimaryContent from './PrimaryContent';
import SecondaryContent from './SecondaryContent';
import TertiaryContent from './TertiaryContent';
import QuarternaryContent from './QuarternaryContent';

import { Normalize } from 'utils/types';

export type THotelsSection =
  Normalize<Queries.IndustryDesktopHotelsData_PrismicIndustryDataDesktopIndustryHotels_Fragment>;

enum HOTELS_TEMPLATE_AREAS {
  primaryContent = 'primary-content',
  secondaryContent = 'secondary-content',
}

const Container = styled.div`
  display: grid;
  position: relative;
  align-content: center;
  overflow: hidden;
`;

const BackgroundContainer = styled.div`
  display: grid;
  position: relative;
  grid-template-areas:
    '.'
    '${HOTELS_TEMPLATE_AREAS.primaryContent}'
    '.'
    '${HOTELS_TEMPLATE_AREAS.secondaryContent}'
    '.';
  grid-template-rows:
    22.875fr
    auto
    79.875fr
    auto
    11.5fr;
  align-content: center;
  margin-bottom: -1px;
  height: 276.5rem;
  overflow: hidden;
`;

const BackgroundImage = styled(GatsbyImage)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const StyledPrimaryContent = styled(PrimaryContent)`
  grid-area: ${HOTELS_TEMPLATE_AREAS.primaryContent};
`;

const StyledSecondaryContent = styled(SecondaryContent)`
  grid-area: ${HOTELS_TEMPLATE_AREAS.secondaryContent};
`;

const HotelsSection: React.FC<THotelsSection> = sliceData => {
  const {
    primaryBackgroundImage,
    primaryTitle,
    primaryCardTexts,
    secondaryBackgroundImage,
    secondaryTitle,
    secondaryPanelTexts,
    tertiaryBackgroundImage,
    tertiaryTitle,
    tertiaryCardTexts,
    quarternaryTexts,
  } = useHotelsData(sliceData);

  return (
    <Container>
      {primaryBackgroundImage ? <GatsbyImage {...primaryBackgroundImage} /> : null}
      <BackgroundContainer>
        {secondaryBackgroundImage ? <BackgroundImage {...secondaryBackgroundImage} /> : null}
        <StyledPrimaryContent title={primaryTitle} cardTexts={primaryCardTexts} />
        <StyledSecondaryContent title={secondaryTitle} panelTexts={secondaryPanelTexts} />
      </BackgroundContainer>
      <TertiaryContent title={tertiaryTitle} cardTexts={tertiaryCardTexts} />
      {tertiaryBackgroundImage ? <GatsbyImage {...tertiaryBackgroundImage} /> : null}
      <QuarternaryContent texts={quarternaryTexts} />
    </Container>
  );
};

export const desktopHotelsFragment = graphql`
  fragment IndustryDesktopHotelsData on PrismicIndustryDataDesktopSlicesType {
    ... on PrismicIndustryDataDesktopIndustryHotels {
      slice_type

      ...IndustryDesktopHotelsPrimaryContentData
      ...IndustryDesktopHotelsSecondaryContentData
      ...IndustryDesktopHotelsTertiaryContentData
      ...IndustryDesktopHotelsQuarternaryContentData

      primary {
        industry_hotels_primary_background_image {
          gatsbyImageData
          alt
        }
        industry_hotels_secondary_background_image {
          gatsbyImageData
          alt
        }
        industry_hotels_tertiary_background_image {
          gatsbyImageData
          alt
        }
      }
    }
  }
`;

export default HotelsSection;
