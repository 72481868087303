import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { css, PropsOf } from '@emotion/react';

import desktopIndustriesMap from './desktopIndustriesMap';

import { useIndustry } from '../IndustryContext';

const Container = styled.div`
  ${({ theme: { mq } }) => css`
    display: block;

    ${mq.md} {
      && > * {
        display: none;
      }
    }
  `}
`;

const DesktopContent: React.FC = () => {
  const { desktop } = useIndustry().industry.data;

  return desktop.length ? (
    <Container>
      {desktop.map((desktopData, index) => {
        const type = (desktopData as { slice_type: string }).slice_type;
        const Section = desktopIndustriesMap[type];

        return Section ? (
          <Section key={`${type}${index}`} {...(desktopData as PropsOf<typeof Section>)} />
        ) : null;
      })}
    </Container>
  ) : null;
};

export const desktopContentFragment = graphql`
  fragment IndustryDesktopContentData on PrismicIndustry {
    data {
      desktop {
        ...IndustryDesktopRestaurantsData
        ...IndustryDesktopRetailData
        ...IndustryDesktopSalonsSpaData
        ...IndustryDesktopOfficesData
        ...IndustryDesktopFashionShowsData
        ...IndustryDesktopHotelsData
        ...IndustryDesktopCircularExpansionSolutionsData
      }
    }
  }
`;

export default DesktopContent;
