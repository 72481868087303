import { useRef } from 'react';
import { gsap } from 'gsap';

import { useAnimationEffect, AnimationEnabledBreakpoints } from 'animations';

export const useFadeIn = ({
  enabledBreakpoint = void 0,
}: { enabledBreakpoint?: AnimationEnabledBreakpoints } = {}) => {
  const animationContainerRef = useRef<HTMLDivElement>(null);

  useAnimationEffect(
    () => {
      if (animationContainerRef?.current) {
        gsap.fromTo(
          animationContainerRef.current,
          {
            opacity: 0,
            translateY: '25%',
          },
          {
            scrollTrigger: {
              trigger: animationContainerRef.current,
              start: 'top-=25% bottom',
            },
            opacity: 1,
            translateY: 0,
            duration: 1.5,
          }
        );
      }
    },
    [animationContainerRef.current],
    enabledBreakpoint
  );

  return animationContainerRef;
};
