import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';

import { FadeIn } from 'animations/FadeIn';
import { RichText } from 'components/Prismic';
import { Classname } from 'utils/types';

interface ISecondaryContent extends Classname {
  title: RichTextBlock[];
  description: RichTextBlock[];
}

const Container = styled(FadeIn)`
  display: grid;
  row-gap: 6.5rem;
  justify-items: center;
  justify-self: center;
  z-index: 1;
  border-radius: 3rem;
  background-color: #8c817780;
  padding: 4.25rem 3.125rem 6.375rem;
  backdrop-filter: blur(1.5625rem);
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > h2 {
      text-align: center;
      line-height: 0.965;
      letter-spacing: -0.04em;
      color: ${colors.primary};
      font-size: 9rem;

      ${tp.useFont(fonts => fonts.Switzer.lightest)};

      strong {
        ${tp.useFont(fonts => fonts.Switzer.regular)};
      }
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > p {
      line-height: 1.2;
      color: ${colors.white};
      font-size: 3.25rem;

      ${tp.useFont(fonts => fonts.Switzer.lightest)};
    }
  `}
`;

const SecondaryContent: React.FC<ISecondaryContent> = ({ className, title, description }) => {
  return (
    <Container className={className}>
      <Title render={title} />
      <Description render={description} />
    </Container>
  );
};

export const desktopRestaurantsSecondaryContentFragment = graphql`
  fragment IndustryDesktopRestaurantsSecondaryContentData on PrismicIndustryDataDesktopIndustryRestaurants {
    primary {
      industry_restaurants_secondary_title {
        richText
      }
      industry_restaurants_secondary_description {
        richText
      }
    }
  }
`;

export default SecondaryContent;
