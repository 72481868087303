import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';

import { RichText } from 'components/Prismic';
import { Classname } from 'utils/types';
import { useFadeIn } from 'utils/animations/useFadeIn';

interface ISecondaryContent extends Classname {
  title: RichTextBlock[];
  description: RichTextBlock[];
}

const Container = styled.div`
  ${({ theme: { colors } }) => css`
    display: grid;
    row-gap: 1.375rem;
    justify-items: center;
    justify-self: center;
    border-radius: 3rem;
    background-color: ${colors.black}80;
    padding: 4.25rem 3.125rem 2.875rem;
  `}
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > h2 {
      text-align: center;
      line-height: 0.965;
      letter-spacing: -0.04em;
      color: ${colors.white};
      font-size: 9rem;

      ${tp.useFont(fonts => fonts.Switzer.lightest)};

      strong {
        ${tp.useFont(fonts => fonts.Switzer.regular)};
      }
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > p {
      line-height: 1.2;
      color: ${colors.white};
      font-size: 3.25rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const SecondaryContent: React.FC<ISecondaryContent> = ({ className, title, description }) => {
  const containerRef = useFadeIn();

  return (
    <Container className={className} ref={containerRef}>
      <Title render={title} />
      <Description render={description} />
    </Container>
  );
};

export const desktopFashionShowsSecondaryContentFragment = graphql`
  fragment IndustryDesktopFashionShowsSecondaryContentData on PrismicIndustryDataDesktopIndustryFashionShows {
    primary {
      industry_fashion_shows_secondary_title {
        richText
      }
      industry_fashion_shows_secondary_description {
        richText
      }
    }
  }
`;

export default SecondaryContent;
