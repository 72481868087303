import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';

import { RichText } from 'components/Prismic';
import { useFadeIn } from 'utils/animations/useFadeIn';

interface IQuarternaryContent {
  texts: { title: RichTextBlock[]; description: RichTextBlock[] }[];
}

const Container = styled.div`
  display: grid;
  align-items: flex-start;
  justify-items: center;
  background-color: #bdbdbd;
  padding-top: 26.375rem;
  height: 139.625rem;
`;

const Content = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  row-gap: 6.875rem;
  column-gap: 14rem;
  align-content: flex-start;
  justify-items: center;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    &::after {
      display: block;
      margin-top: 6.25rem;
      background-color: ${colors.black};
      width: 11.5rem;
      height: 0.25rem;
      content: '';
    }

    & > h2 {
      text-align: center;
      line-height: 0.965;
      letter-spacing: -0.04em;
      color: ${colors.black};
      font-size: 7.5rem;

      ${tp.useFont(fonts => fonts.Switzer.lightest)};

      em {
        color: ${colors.white};
        font-style: normal;
      }

      strong,
      em {
        ${tp.useFont(fonts => fonts.Switzer.regular)};
      }
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > p {
      text-align: center;
      line-height: 1.2;
      color: ${colors.black}f5;
      font-size: 3.25rem;

      ${tp.useFont(fonts => fonts.Switzer.lightest)};
    }
  `}
`;

const QuarternaryContent: React.FC<IQuarternaryContent> = ({ texts }) => {
  const contentRef = useFadeIn();

  return (
    <Container>
      <Content ref={contentRef}>
        {texts.map(({ title, description }) => (
          <>
            <Title render={title} />
            <Description render={description} />
          </>
        ))}
      </Content>
    </Container>
  );
};

export const desktopHotelsQuarternaryContentFragment = graphql`
  fragment IndustryDesktopHotelsQuarternaryContentData on PrismicIndustryDataDesktopIndustryHotels {
    items {
      industry_hotels_quarternary_panel_title {
        richText
      }
      industry_hotels_quarternary_panel_description {
        richText
      }
    }
  }
`;

export default QuarternaryContent;
