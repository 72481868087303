import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';

import { RichText } from 'components/Prismic';
import { Classname } from 'utils/types';
import { useFadeIn } from 'utils/animations/useFadeIn';

interface ISecondaryContent extends Classname {
  title: RichTextBlock[];
  description: RichTextBlock[];
}

const Container = styled.div`
  display: grid;
  row-gap: 5.5rem;
  justify-items: center;
  justify-self: center;
  z-index: 1;
  border-radius: 3rem;
  background-color: #8c817780;
  padding: 7.375rem 3.125rem 16rem;
  backdrop-filter: blur(1.5625rem);
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > h2 {
      text-align: center;
      line-height: 0.965;
      letter-spacing: -0.04em;
      color: ${colors.primary};
      font-size: 9rem;

      ${tp.useFont(fonts => fonts.Switzer.lightest)};

      strong {
        ${tp.useFont(fonts => fonts.Switzer.regular)};
      }
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > p {
      line-height: 1.2;
      color: ${colors.white};
      font-size: 3.25rem;

      ${tp.useFont(fonts => fonts.Switzer.lightest)};
    }
  `}
`;

const SecondaryContent = forwardRef<HTMLDivElement, ISecondaryContent>(function SecondaryContent(
  { className, title, description },
  forwardedRef
) {
  const containerRef: React.MutableRefObject<HTMLDivElement | null> = useFadeIn();

  return (
    <Container
      className={className}
      ref={element => {
        if (containerRef?.current) {
          containerRef.current = element;
        }

        if ((forwardedRef as React.MutableRefObject<HTMLDivElement | null>)?.current) {
          (forwardedRef as React.MutableRefObject<HTMLDivElement | null>).current = element;
        }
      }}
    >
      <Title render={title} />
      <Description render={description} />
    </Container>
  );
});

export const desktopSalonsSpaSecondaryContentFragment = graphql`
  fragment IndustryDesktopSalonsSpaSecondaryContentData on PrismicIndustryDataDesktopIndustrySalonsSpa {
    primary {
      industry_salons_spa_secondary_title {
        richText
      }
      industry_salons_spa_secondary_description {
        richText
      }
    }
  }
`;

export default SecondaryContent;
