import React from 'react';
import { graphql, PageProps } from 'gatsby';

import { withIndustryProvider } from './IndustryContext';
import { useIndustryData } from './hooks';
import HeroSection from './HeroSection';
import DesktopContent from './DesktopContent';
import Content from './Content';

import Faq from 'components/Faq';
import Layout from 'components/Layout';

const Industry: React.FC<Omit<PageProps, 'data'>> = () => {
  const { layoutProps, data, ids } = useIndustryData();

  return (
    <Layout {...layoutProps}>
      <HeroSection id={ids.hero} />
      <DesktopContent />
      <Content />
      <Faq id={ids.faq} data={data} />
    </Layout>
  );
};

export const industryFragment = graphql`
  fragment IndustryData on PrismicIndustry {
    ...IndustryHeroData
    ...IndustryDesktopContentData
    ...IndustryContentData
    ...FaqData
  }

  fragment IndustryLayoutData on PrismicIndustry {
    lang
    alternate_languages {
      document {
        ... on PrismicIndustry {
          lang
          url
        }
      }
    }

    data {
      compact_title
      is_dark
    }
  }
`;

export default withIndustryProvider(Industry);
