import RestaurantsSection, { RESTAURANTS_SLICE_TYPE } from './RestaurantsSection';
import RetailSection, { RETAIL_SLICE_TYPE } from './RetailSection';
import SalonsSpaSection, { SALONS_SPA_SLICE_TYPE } from './SalonsSpaSection';
import OfficesSection, { OFFICES_SLICE_TYPE } from './OfficesSection';
import FashionShowsSection, { FASHION_SHOWS_SLICE_TYPE } from './FashionShowsSection';
import HotelsSection, { HOTELS_SLICE_TYPE } from './HotelsSection';
import CircularExpansionSolutionsSection, {
  CIRCULAR_EXPANSION_SOLUTIONS_SLICE_TYPE,
} from './CircularExpansionSolutionsSection';

export default {
  [RESTAURANTS_SLICE_TYPE]: RestaurantsSection,
  [RETAIL_SLICE_TYPE]: RetailSection,
  [SALONS_SPA_SLICE_TYPE]: SalonsSpaSection,
  [OFFICES_SLICE_TYPE]: OfficesSection,
  [FASHION_SHOWS_SLICE_TYPE]: FashionShowsSection,
  [HOTELS_SLICE_TYPE]: HotelsSection,
  [CIRCULAR_EXPANSION_SOLUTIONS_SLICE_TYPE]: CircularExpansionSolutionsSection,
};
