import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';

import { Classname } from 'utils/types';
import { RichText } from 'components/Prismic';
import { useFadeIn } from 'utils/animations/useFadeIn';

interface IPrimaryContent extends Classname {
  title: RichTextBlock[];
  cardTexts: RichTextBlock[];
}

const Container = styled.div`
  display: grid;
  row-gap: 61.875rem;
  align-content: flex-end;
  justify-items: center;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    justify-self: flex-start;

    & > h2 {
      padding-left: 9rem;
      text-align: center;
      line-height: 1.04;
      letter-spacing: -0.1em;
      color: ${colors.primary};
      font-size: 11.875rem;

      ${tp.useFont(fonts => fonts.Switzer.lightest)};

      strong {
        ${tp.useFont(fonts => fonts.Switzer.regular)};
      }
    }
  `}
`;

const CardTexts = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    justify-self: stretch;

    & > ul {
      display: grid;
      grid-template-rows: auto auto;
      grid-auto-columns: max-content;
      grid-auto-flow: column;
      row-gap: 4.25rem;
      justify-content: space-between;
      padding: 0 11.5rem;
      width: 100%;
      line-height: 1.09;
      color: ${colors.black}f5;
      font-size: 3.25rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};

      & em {
        text-align: center;
        line-height: 1.04;
        letter-spacing: -0.1em;
        color: ${colors.black}b3;
        font-size: 4rem;
        font-style: normal;

        ${tp.useFont(fonts => fonts.Switzer.light)};
      }

      & > li {
        display: contents;
      }
    }
  `}
`;

const PrimaryContent: React.FC<IPrimaryContent> = ({ className, title, cardTexts }) => {
  const containerRef = useFadeIn();

  return (
    <Container className={className} ref={containerRef}>
      <Title render={title} />
      <CardTexts render={cardTexts} />
    </Container>
  );
};

export const desktopFashionShowsPrimaryContentFragment = graphql`
  fragment IndustryDesktopFashionShowsPrimaryContentData on PrismicIndustryDataDesktopIndustryFashionShows {
    primary {
      industry_fashion_shows_primary_title {
        richText
      }
      industry_fashion_shows_primary_card_texts {
        richText
      }
    }
  }
`;

export default PrimaryContent;
