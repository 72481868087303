import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useRetailData } from './hooks';
import PrimaryContent from './PrimaryContent';
import SecondaryContent from './SecondaryContent';
import TertiaryContent from './TertiaryContent';
import QuarternaryContent from './QuarternaryContent';

import { Normalize } from 'utils/types';

export type TRetailSection =
  Normalize<Queries.IndustryDesktopRetailData_PrismicIndustryDataDesktopIndustryRetail_Fragment>;

enum RETAIL_TEMPLATE_AREAS {
  primaryContent = 'primary-content',
  secondaryContent = 'secondary-content',
  tertiaryContent = 'tertiary-content',
  quarternaryContent = 'quarternary-content',
}

const Container = styled.div`
  display: grid;
  position: relative;
  grid-template-areas:
    '.'
    '${RETAIL_TEMPLATE_AREAS.primaryContent}'
    '.'
    '${RETAIL_TEMPLATE_AREAS.secondaryContent}'
    '.'
    '${RETAIL_TEMPLATE_AREAS.tertiaryContent}'
    '.'
    '${RETAIL_TEMPLATE_AREAS.quarternaryContent}'
    '.';
  grid-template-rows:
    7.5fr
    auto
    20.875fr
    auto
    8.5fr
    auto
    19fr
    auto
    19.125fr;
  align-content: center;
  margin-bottom: -1px;
  height: 425.125rem;
  overflow: hidden;
`;

const BackgroundImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 140%;
`;

const BackgroundImage = styled(GatsbyImage)`
  height: 100%;
`;

const StyledPrimaryContent = styled(PrimaryContent)`
  grid-area: ${RETAIL_TEMPLATE_AREAS.primaryContent};
`;

const StyledSecondaryContent = styled(SecondaryContent)`
  grid-area: ${RETAIL_TEMPLATE_AREAS.secondaryContent};
`;

const StyledTertiaryContent = styled(TertiaryContent)`
  grid-area: ${RETAIL_TEMPLATE_AREAS.tertiaryContent};
`;

const StyledQuarternaryContent = styled(QuarternaryContent)`
  grid-area: ${RETAIL_TEMPLATE_AREAS.quarternaryContent};
`;

const RetailSection: React.FC<TRetailSection> = sliceData => {
  const {
    containerRef,
    backgroundRef,
    backgroundImage,
    primaryTitle,
    primaryCardTexts,
    secondaryTitle,
    secondaryPanelTexts,
    tertiaryTitle,
    tertiaryCardTexts,
    quarternaryTitle,
    quarternaryDescription,
  } = useRetailData(sliceData);

  return (
    <Container ref={containerRef}>
      {backgroundImage ? (
        <BackgroundImageContainer ref={backgroundRef}>
          <BackgroundImage {...backgroundImage} />
        </BackgroundImageContainer>
      ) : null}
      <StyledPrimaryContent title={primaryTitle} cardTexts={primaryCardTexts} />
      <StyledSecondaryContent title={secondaryTitle} panelTexts={secondaryPanelTexts} />
      <StyledTertiaryContent title={tertiaryTitle} cardTexts={tertiaryCardTexts} />
      <StyledQuarternaryContent title={quarternaryTitle} description={quarternaryDescription} />
    </Container>
  );
};

export const desktopRetailFragment = graphql`
  fragment IndustryDesktopRetailData on PrismicIndustryDataDesktopSlicesType {
    ... on PrismicIndustryDataDesktopIndustryRetail {
      slice_type

      ...IndustryDesktopRetailPrimaryContentData
      ...IndustryDesktopRetailSecondaryContentData
      ...IndustryDesktopRetailTertiaryContentData
      ...IndustryDesktopRetailQuarternaryContentData

      primary {
        industry_retail_background_image {
          gatsbyImageData
          alt
        }
      }
    }
  }
`;

export default RetailSection;
