export interface Coordinate {
  x: number;
  y: number;
}
export const getDistanceFromPageTop = ({ offsetTop, offsetParent }: HTMLElement) =>
  offsetParent ? offsetTop + getDistanceFromPageTop(offsetParent as HTMLElement) : offsetTop;

export const getDistanceFromPageLeft = ({ offsetLeft, offsetParent }: HTMLElement) =>
  offsetParent ? offsetLeft + getDistanceFromPageLeft(offsetParent as HTMLElement) : offsetLeft;

export const findDistance = ([coordinateA, coordinateB]: [Coordinate, Coordinate]): number => {
  const xDistance = Math.abs(coordinateA.x - coordinateB.x);
  const yDistance = Math.abs(coordinateA.y - coordinateB.y);

  return Math.sqrt(Math.abs(xDistance * xDistance + yDistance * yDistance));
};

export const findLargestDistanceFromCenter = (
  cornerCoordinates: Coordinate[],
  centerCoordinate: Coordinate
): number => {
  return Math.ceil(
    cornerCoordinates.reduce((acm, coordinate) => {
      const distance = findDistance([coordinate, centerCoordinate]);
      return acm < distance ? distance : acm;
    }, 0)
  );
};
