import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';

import { RichText } from 'components/Prismic';
import { Classname } from 'utils/types';
import { useFadeIn } from 'utils/animations/useFadeIn';

interface ITertiaryContent extends Classname {
  title: RichTextBlock[];
  cardTexts: RichTextBlock[];
}

const Container = styled.div`
  display: grid;
  row-gap: 5.875rem;
  justify-items: center;
  z-index: 1;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > h2 {
      text-align: center;
      line-height: 1.04;
      letter-spacing: -0.1em;
      color: ${colors.primary};
      font-size: 9rem;

      ${tp.useFont(fonts => fonts.Switzer.regular)};
    }
  `}
`;

const CardTexts = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > ul {
      display: grid;
      grid-template-rows: auto auto;
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
      row-gap: 3.125rem;
      column-gap: 9.75rem;
      line-height: 1.09;
      color: ${colors.black}f5;
      font-size: 3.25rem;

      ${tp.useFont(fonts => fonts.Switzer.lightest)};

      & em {
        line-height: 1.04;
        letter-spacing: -0.1em;
        color: ${colors.black}b3;
        font-size: 4rem;
        font-style: normal;

        ${tp.useFont(fonts => fonts.Switzer.light)};
      }

      & > li {
        display: contents;
      }
    }
  `}
`;

const TertiaryContent: React.FC<ITertiaryContent> = ({ className, title, cardTexts }) => {
  const containerRef = useFadeIn();

  return (
    <Container className={className} ref={containerRef}>
      <Title render={title} />
      <CardTexts render={cardTexts} />
    </Container>
  );
};

export const desktopFashionShowsTertiaryContentFragment = graphql`
  fragment IndustryDesktopFashionShowsTertiaryContentData on PrismicIndustryDataDesktopIndustryFashionShows {
    primary {
      industry_fashion_shows_tertiary_title {
        richText
      }
      industry_fashion_shows_tertiary_card_texts {
        richText
      }
    }
  }
`;

export default TertiaryContent;
