import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';

import { RichText } from 'components/Prismic';
import { useFadeIn } from 'utils/animations/useFadeIn';
import { Classname } from 'utils/types';

interface IQuinaryContent extends Classname {
  title: RichTextBlock[];
  description: RichTextBlock[];
}

const Container = styled.div`
  display: grid;
  row-gap: 8.25rem;
  align-content: flex-start;
  justify-items: center;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > h2 {
      text-align: center;
      line-height: 1.04;
      letter-spacing: -0.1em;
      color: ${colors.backgroundDark};
      font-size: 10.625rem;

      ${tp.useFont(fonts => fonts.Switzer.lightest)};
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > p {
      text-align: center;
      line-height: 1.2;
      color: ${colors.black};
      font-size: 3.25rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const QuinaryContent: React.FC<IQuinaryContent> = ({ className, title, description }) => {
  const containerRef = useFadeIn();

  return (
    <Container className={className} ref={containerRef}>
      <Title render={title} />
      <Description render={description} />
    </Container>
  );
};

export const desktopSalonsSpaQuinaryContentFragment = graphql`
  fragment IndustryDesktopSalonsSpaQuinaryContentData on PrismicIndustryDataDesktopIndustrySalonsSpa {
    primary {
      industry_salons_spa_quinary_title {
        richText
      }
      industry_salons_spa_quinary_description {
        richText
      }
    }
  }
`;

export default QuinaryContent;
