import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';

import { FadeIn } from 'animations/FadeIn';
import { RichText } from 'components/Prismic';
import { Classname } from 'utils/types';

interface ITertiaryContent extends Classname {
  title: RichTextBlock[];
  cardTexts: RichTextBlock[];
}

const Container = styled(FadeIn)`
  display: grid;
  row-gap: 10.125rem;
  justify-items: center;
  z-index: 1;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > h2 {
      text-align: center;
      text-shadow: 0 0.5rem 0.5rem ${colors.black}80;
      line-height: 1.04;
      letter-spacing: -0.1em;
      color: ${colors.white};
      font-size: 10.625rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const CardTexts = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > ul {
      display: grid;
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
      column-gap: 12.5rem;

      & > li {
        backdrop-filter: blur(1.5625rem);
        border-radius: 3rem;
        background-color: #d9d9d980;
        padding: 8.75rem 2.75rem 8.375rem;
        list-style: none;
        line-height: 1.09;
        color: ${colors.black}f5;
        font-size: 3.25rem;

        ${tp.useFont(fonts => fonts.Switzer.lightest)};
      }
    }
  `}
`;

const TertiaryContent: React.FC<ITertiaryContent> = ({ className, title, cardTexts }) => {
  return (
    <Container className={className}>
      <Title render={title} />
      <CardTexts render={cardTexts} />
    </Container>
  );
};

export const desktopRestaurantsTertiaryContentFragment = graphql`
  fragment IndustryDesktopRestaurantsTertiaryContentData on PrismicIndustryDataDesktopIndustryRestaurants {
    primary {
      industry_restaurants_tertiary_title {
        richText
      }
      industry_restaurants_tertiary_card_texts {
        richText
      }
    }
  }
`;

export default TertiaryContent;
