import { useMemo, useRef } from 'react';

import { TCircularExpansionSolutionsSection } from './CircularExpansionSolutionsSection';

import { Normalize } from 'utils/types';
import { richTextWithPlaceholder } from 'utils/prismic';
import useMaskCircle from 'utils/animations/useMaskCircle';

const useTextBackgroundAnimation = () => {
  const titleRef = useRef<HTMLDivElement>(null);

  const { containerRef, contentRef } = useMaskCircle([titleRef]);

  return { containerRef, contentRef, titleRef };
};

export const useTextBackgroundData = ({ primary, items }: TCircularExpansionSolutionsSection) => {
  const {
    industry_circular_expansion_solutions_background_image: backgroundImage,
    industry_circular_expansion_solutions_title: title,
  } = primary;

  const { gatsbyImageData, alt } = backgroundImage;

  const solutionsLinks = useMemo(
    () =>
      items.reduce((acm, { industry_circular_expansion_solutions_solution: solution }) => {
        const { data, url } =
          solution.document as Normalize<Queries.IndustryDesktopCircularExpansionSolutionsSolutionFragment>;

        if (!data.compact_title) return acm;

        return [...acm, { label: data.compact_title, url }];
      }, []),
    [items]
  );

  return {
    title: richTextWithPlaceholder(title, ''),
    backgroundImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
    solutionsLinks,
    ...useTextBackgroundAnimation(),
  };
};
