import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';

import { RichText } from 'components/Prismic';
import { Classname } from 'utils/types';
import { useFadeIn } from 'utils/animations/useFadeIn';

interface ISecondaryContent extends Classname {
  title: RichTextBlock[];
  panelTexts: RichTextBlock[];
}

const Container = styled.div`
  display: grid;
  row-gap: 7.125rem;
  justify-items: center;
  justify-self: center;
  border-radius: 3rem;
  background-color: #554a4840;
  padding: 5.875rem 5.375rem 11.75rem;
  backdrop-filter: blur(1.5625rem);
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > h2 {
      text-align: center;
      line-height: 1.04;
      letter-spacing: -0.1em;
      color: ${colors.primary};
      font-size: 9rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const PanelTexts = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > ul {
      display: grid;
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
      column-gap: 17.125rem;

      & > li {
        list-style: none;
        line-height: 1.09;
        color: ${colors.white};
        font-size: 3.25rem;

        ${tp.useFont(fonts => fonts.Switzer.lightest)};

        strong {
          ${tp.useFont(fonts => fonts.Switzer.semiBold)};
        }
      }
    }
  `}
`;

const SecondaryContent: React.FC<ISecondaryContent> = ({ className, title, panelTexts }) => {
  const containerRef = useFadeIn();

  return (
    <Container className={className} ref={containerRef}>
      <Title render={title} />
      <PanelTexts render={panelTexts} />
    </Container>
  );
};

export const desktopRetailSecondaryContentFragment = graphql`
  fragment IndustryDesktopRetailSecondaryContentData on PrismicIndustryDataDesktopIndustryRetail {
    primary {
      industry_retail_secondary_title {
        richText
      }
      industry_retail_secondary_panel_texts {
        richText
      }
    }
  }
`;

export default SecondaryContent;
