import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';

import { FadeIn } from 'animations/FadeIn';
import { RichText } from 'components/Prismic';

interface IQuinaryContent {
  title: RichTextBlock[];
  description: RichTextBlock[];
}

const Container = styled.div`
  box-sizing: border-box;
  display: grid;
  background-color: #757575;
  padding-top: 41.125rem;
  height: 139.625rem;
`;

const Content = styled(FadeIn)`
  display: grid;
  row-gap: 3rem;
  align-content: flex-start;
  justify-items: center;
  height: 100%;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    &::after {
      display: block;
      margin-top: 3rem;
      background-color: ${colors.white};
      width: 11.5rem;
      height: 0.25rem;
      content: '';
    }

    & > h2 {
      text-align: center;
      line-height: 0.965;
      letter-spacing: -0.04em;
      color: ${colors.white};
      font-size: 8rem;

      ${tp.useFont(fonts => fonts.Switzer.lightest)};

      strong {
        ${tp.useFont(fonts => fonts.Switzer.lightBold)};
      }
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > p {
      text-align: center;
      line-height: 1.2;
      color: ${colors.white}f5;
      font-size: 3.25rem;

      ${tp.useFont(fonts => fonts.Switzer.lightest)};
    }
  `}
`;

const QuinaryContent: React.FC<IQuinaryContent> = ({ title, description }) => {
  return (
    <Container>
      <Content>
        <Title render={title} />
        <Description render={description} />
      </Content>
    </Container>
  );
};

export const desktopRestaurantsQuinaryContentFragment = graphql`
  fragment IndustryDesktopRestaurantsQuinaryContentData on PrismicIndustryDataDesktopIndustryRestaurants {
    primary {
      industry_restaurants_quinary_title {
        richText
      }
      industry_restaurants_quinary_description {
        richText
      }
    }
  }
`;

export default QuinaryContent;
