import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useSalonsSpaData } from './hooks';
import PrimaryContent from './PrimaryContent';
import SecondaryContent from './SecondaryContent';
import TertiaryContent from './TertiaryContent';
import QuarternaryContent from './QuarternaryContent';
import QuinaryContent from './QuinaryContent';

import { Normalize } from 'utils/types';

export type TSalonsSpaSection =
  Normalize<Queries.IndustryDesktopSalonsSpaData_PrismicIndustryDataDesktopIndustrySalonsSpa_Fragment>;

enum RESTAURANTS_TEMPLATE_AREAS {
  primaryContent = 'primary-content',
  secondaryContent = 'secondary-content',
  tertiaryContent = 'tertiary-content',
  quarternaryContent = 'quarternary-content',
  quinaryContent = 'quinary-content',
}

const Container = styled.div`
  display: grid;
  position: relative;
  grid-template-areas:
    '.'
    '${RESTAURANTS_TEMPLATE_AREAS.primaryContent}'
    '.'
    '${RESTAURANTS_TEMPLATE_AREAS.secondaryContent}'
    '.'
    '${RESTAURANTS_TEMPLATE_AREAS.tertiaryContent}'
    '.'
    '${RESTAURANTS_TEMPLATE_AREAS.quarternaryContent}'
    '.'
    '${RESTAURANTS_TEMPLATE_AREAS.quinaryContent}'
    '.';
  grid-template-rows:
    10.375fr
    auto
    244.875fr
    auto
    17.875fr
    auto
    10.75fr
    auto
    12fr
    auto
    43.5fr;
  align-content: center;
  margin-bottom: -1px;
  height: 645.75rem;
  overflow: hidden;
`;

const BackgroundImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 150%;
`;

const BackgroundImage = styled(GatsbyImage)`
  height: 100%;
`;

const StyledPrimaryContent = styled(PrimaryContent)`
  grid-area: ${RESTAURANTS_TEMPLATE_AREAS.primaryContent};
`;

const StyledSecondaryContent = styled(SecondaryContent)`
  grid-area: ${RESTAURANTS_TEMPLATE_AREAS.secondaryContent};
`;

const StyledTertiaryContent = styled(TertiaryContent)`
  grid-area: ${RESTAURANTS_TEMPLATE_AREAS.tertiaryContent};
`;

const StyledQuarternaryContent = styled(QuarternaryContent)`
  grid-area: ${RESTAURANTS_TEMPLATE_AREAS.quarternaryContent};
`;

const StyledQuinaryContent = styled(QuinaryContent)`
  grid-area: ${RESTAURANTS_TEMPLATE_AREAS.quinaryContent};
`;

const SalonsSpaSection: React.FC<TSalonsSpaSection> = sliceData => {
  const {
    containerRef,
    backgroundRef,
    primaryContentRef,
    secondaryContentRef,
    backgroundImage,
    primaryTitle,
    primaryPanelTexts,
    secondaryTitle,
    secondaryDescription,
    tertiaryTitle,
    tertiaryCardTexts,
    quarternaryTitle,
    quarternaryDescription,
    quinaryTitle,
    quinaryDescription,
  } = useSalonsSpaData(sliceData);

  return (
    <Container ref={containerRef}>
      {backgroundImage ? (
        <BackgroundImageContainer ref={backgroundRef}>
          <BackgroundImage {...backgroundImage} />
        </BackgroundImageContainer>
      ) : null}
      <StyledPrimaryContent
        title={primaryTitle}
        panelTexts={primaryPanelTexts}
        ref={primaryContentRef}
      />
      <StyledSecondaryContent
        title={secondaryTitle}
        description={secondaryDescription}
        ref={secondaryContentRef}
      />
      <StyledTertiaryContent title={tertiaryTitle} cardTexts={tertiaryCardTexts} />
      <StyledQuarternaryContent title={quarternaryTitle} description={quarternaryDescription} />
      <StyledQuinaryContent title={quinaryTitle} description={quinaryDescription} />
    </Container>
  );
};

export const desktopSalonsSpaFragment = graphql`
  fragment IndustryDesktopSalonsSpaData on PrismicIndustryDataDesktopSlicesType {
    ... on PrismicIndustryDataDesktopIndustrySalonsSpa {
      slice_type

      ...IndustryDesktopSalonsSpaPrimaryContentData
      ...IndustryDesktopSalonsSpaSecondaryContentData
      ...IndustryDesktopSalonsSpaTertiaryContentData
      ...IndustryDesktopSalonsSpaQuarternaryContentData
      ...IndustryDesktopSalonsSpaQuinaryContentData

      primary {
        industry_salons_spa_background_image {
          gatsbyImageData
          alt
        }
      }
    }
  }
`;

export default SalonsSpaSection;
