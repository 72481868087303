import { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';

import { TFashionShowsSection } from './FashionShowsSection';

import { richTextWithPlaceholder } from 'utils/prismic';

const useFashionShowsAnimation = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const context = gsap.context(() => {
      gsap.fromTo(
        backgroundRef.current,
        {
          top: '0%',
        },
        {
          scrollTrigger: {
            trigger: containerRef.current,
            start: 'top top',
            end: `bottom bottom`,
            scrub: true,
          },
          top: '-40%',
        }
      );
    });

    return () => context.revert();
  }, []);

  return { containerRef, backgroundRef };
};

export const useFashionShowsData = ({ primary }: TFashionShowsSection) => {
  const {
    industry_fashion_shows_background_image: backgroundImage,
    industry_fashion_shows_primary_title: primaryTitle,
    industry_fashion_shows_primary_card_texts: primaryCardTexts,
    industry_fashion_shows_secondary_title: secondaryTitle,
    industry_fashion_shows_secondary_description: secondaryDescription,
    industry_fashion_shows_tertiary_title: tertiaryTitle,
    industry_fashion_shows_tertiary_card_texts: tertiaryCardTexts,
    industry_fashion_shows_quarternary_title: quarternaryTitle,
    industry_fashion_shows_quarternary_description: quarternaryDescription,
  } = primary;

  const { gatsbyImageData, alt } = backgroundImage;

  return {
    backgroundImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
    primaryTitle: richTextWithPlaceholder(primaryTitle, ''),
    primaryCardTexts: richTextWithPlaceholder(primaryCardTexts, ''),
    secondaryTitle: richTextWithPlaceholder(secondaryTitle, ''),
    secondaryDescription: richTextWithPlaceholder(secondaryDescription, ''),
    tertiaryTitle: richTextWithPlaceholder(tertiaryTitle, ''),
    tertiaryCardTexts: richTextWithPlaceholder(tertiaryCardTexts, ''),
    quarternaryTitle: richTextWithPlaceholder(quarternaryTitle, ''),
    quarternaryDescription: richTextWithPlaceholder(quarternaryDescription, ''),
    ...useFashionShowsAnimation(),
  };
};
