import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';

import { RichText } from 'components/Prismic';
import { Classname } from 'utils/types';
import { useFadeIn } from 'utils/animations/useFadeIn';

interface ITertiaryContent extends Classname {
  title: RichTextBlock[];
  cardTexts: RichTextBlock[];
}

const Container = styled.div`
  display: grid;
  row-gap: 9.375rem;
  justify-items: center;
  z-index: 1;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > h2 {
      text-align: center;
      text-shadow: 0 0.5rem 0.5rem ${colors.black}80;
      line-height: 1.04;
      letter-spacing: -0.1em;
      color: ${colors.white};
      font-size: 10.625rem;

      ${tp.useFont(fonts => fonts.Switzer.lightest)};
    }
  `}
`;

const CardTexts = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > ul {
      display: grid;
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
      column-gap: 12.5rem;

      & > li {
        backdrop-filter: blur(0.9375rem);
        border-radius: 3rem;
        background-color: #d9d9d980;
        padding: 7.5rem 2.75rem 8.75rem;
        list-style: none;
        line-height: 1.09;
        color: ${colors.black}f5;
        font-size: 3.25rem;

        ${tp.useFont(fonts => fonts.Switzer.light)};

        strong {
          ${tp.useFont(fonts => fonts.Switzer.lightBold)};
        }
      }
    }
  `}
`;

const TertiaryContent: React.FC<ITertiaryContent> = ({ className, title, cardTexts }) => {
  const containerRef = useFadeIn();

  return (
    <Container className={className} ref={containerRef}>
      <Title render={title} />
      <CardTexts render={cardTexts} />
    </Container>
  );
};

export const desktopRetailTertiaryContentFragment = graphql`
  fragment IndustryDesktopRetailTertiaryContentData on PrismicIndustryDataDesktopIndustryRetail {
    primary {
      industry_retail_tertiary_title {
        richText
      }
      industry_retail_tertiary_card_texts {
        richText
      }
    }
  }
`;

export default TertiaryContent;
