import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';

import { RichText } from 'components/Prismic';
import { Classname } from 'utils/types';
import { useFadeIn } from 'utils/animations/useFadeIn';

interface IQuarternaryContent extends Classname {
  title: RichTextBlock[];
  description: RichTextBlock[];
}

const Container = styled.div`
  display: grid;
  row-gap: 3rem;
  justify-items: center;
  justify-self: center;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > h2 {
      text-align: center;
      line-height: 1.04;
      letter-spacing: -0.1em;
      color: ${colors.black}b3;
      font-size: 10.625rem;

      ${tp.useFont(fonts => fonts.Switzer.regular)};
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > p {
      border-radius: 3rem;
      background-color: ${colors.white}80;
      backdrop-filter: blur(1.5625rem);
      padding: 8.375rem 5rem 8.75rem;
      text-align: center;
      line-height: 1.2;
      color: ${colors.black}f5;
      font-size: 3.25rem;

      ${tp.useFont(fonts => fonts.Switzer.lightest)};
    }
  `}
`;

const QuarternaryContent: React.FC<IQuarternaryContent> = ({ className, title, description }) => {
  const containerRef = useFadeIn();

  return (
    <Container className={className} ref={containerRef}>
      <Title render={title} />
      <Description render={description} />
    </Container>
  );
};

export const desktopOfficesQuarternaryContentFragment = graphql`
  fragment IndustryDesktopOfficesQuarternaryContentData on PrismicIndustryDataDesktopIndustryOffices {
    primary {
      industry_offices_quarternary_title {
        richText
      }
      industry_offices_quarternary_description {
        richText
      }
    }
  }
`;

export default QuarternaryContent;
