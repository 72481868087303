import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';

import { TSalonsSpaSection } from './SalonsSpaSection';

import { getDistanceFromPageTop } from 'utils/animations/helpers';
import { richTextWithPlaceholder } from 'utils/prismic';

const useSalonsSpaAnimation = () => {
  const [resizeId, setResizeId] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);
  const primaryContentRef = useRef<HTMLDivElement>(null);
  const secondaryContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => setResizeId(id => id + 1);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useLayoutEffect(() => {
    const context = gsap.context(() => {
      gsap.fromTo(
        backgroundRef.current,
        {
          top: '0%',
        },
        {
          scrollTrigger: {
            trigger: containerRef.current,
            start: 'top top',
            end: `bottom bottom`,
            scrub: true,
          },
          top: '-50%',
        }
      );
    });

    return () => context.revert();
  }, []);

  useLayoutEffect(() => {
    const context = gsap.context(() => {
      if (primaryContentRef.current && secondaryContentRef.current) {
        const distanceBetweenContents =
          getDistanceFromPageTop(secondaryContentRef.current) -
          getDistanceFromPageTop(primaryContentRef.current);

        const primaryContentHeight = primaryContentRef.current.offsetHeight;
        const secondaryContentHeight = secondaryContentRef.current.offsetHeight;

        const animationDistance =
          distanceBetweenContents - primaryContentHeight - secondaryContentHeight / 2;

        gsap.fromTo(
          primaryContentRef.current,
          {
            translateY: 0,
          },
          {
            scrollTrigger: {
              trigger: primaryContentRef.current,
              start: `top center`,
              endTrigger: secondaryContentRef.current,
              end: `top-=50% bottom`,
              scrub: true,
            },
            translateY: animationDistance,
            ease: 'none',
          }
        );
      }
    });

    return () => context.revert();
  }, [resizeId]);

  return { containerRef, backgroundRef, primaryContentRef, secondaryContentRef };
};

export const useSalonsSpaData = ({ primary }: TSalonsSpaSection) => {
  const {
    industry_salons_spa_background_image: backgroundImage,
    industry_salons_spa_primary_title: primaryTitle,
    industry_salons_spa_primary_panel_texts: primaryPanelTexts,
    industry_salons_spa_secondary_title: secondaryTitle,
    industry_salons_spa_secondary_description: secondaryDescription,
    industry_salons_spa_tertiary_title: tertiaryTitle,
    industry_salons_spa_tertiary_card_texts: tertiaryCardTexts,
    industry_salons_spa_quarternary_title: quarternaryTitle,
    industry_salons_spa_quarternary_description: quarternaryDescription,
    industry_salons_spa_quinary_title: quinaryTitle,
    industry_salons_spa_quinary_description: quinaryDescription,
  } = primary;

  const { gatsbyImageData, alt } = backgroundImage;

  return {
    backgroundImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
    primaryTitle: richTextWithPlaceholder(primaryTitle, ''),
    primaryPanelTexts: richTextWithPlaceholder(primaryPanelTexts, ''),
    secondaryTitle: richTextWithPlaceholder(secondaryTitle, ''),
    secondaryDescription: richTextWithPlaceholder(secondaryDescription, ''),
    tertiaryTitle: richTextWithPlaceholder(tertiaryTitle, ''),
    tertiaryCardTexts: richTextWithPlaceholder(tertiaryCardTexts, ''),
    quarternaryTitle: richTextWithPlaceholder(quarternaryTitle, ''),
    quarternaryDescription: richTextWithPlaceholder(quarternaryDescription, ''),
    quinaryTitle: richTextWithPlaceholder(quinaryTitle, ''),
    quinaryDescription: richTextWithPlaceholder(quinaryDescription, ''),
    ...useSalonsSpaAnimation(),
  };
};
