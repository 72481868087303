import { THotelsSection } from './HotelsSection';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useHotelsData = ({ primary, items }: THotelsSection) => {
  const {
    industry_hotels_primary_background_image: primaryBackgroundImage,
    industry_hotels_secondary_background_image: secondaryBackgroundImage,
    industry_hotels_tertiary_background_image: tertiaryBackgroundImage,
    industry_hotels_primary_title: primaryTitle,
    industry_hotels_primary_card_texts: primaryCardTexts,
    industry_hotels_secondary_title: secondaryTitle,
    industry_hotels_secondary_panel_texts: secondaryPanelTexts,
    industry_hotels_tertiary_title: tertiaryTitle,
    industry_hotels_tertiary_card_texts: tertiaryCardTexts,
  } = primary;

  const quarternaryTexts = items.map(
    ({
      industry_hotels_quarternary_panel_title: quarternaryTitle,
      industry_hotels_quarternary_panel_description: quarternaryDescription,
    }) => ({
      title: richTextWithPlaceholder(quarternaryTitle, ''),
      description: richTextWithPlaceholder(quarternaryDescription, ''),
    })
  );

  return {
    primaryBackgroundImage: primaryBackgroundImage.gatsbyImageData
      ? {
          image: primaryBackgroundImage.gatsbyImageData,
          alt: primaryBackgroundImage.alt ?? '',
        }
      : null,
    primaryTitle: richTextWithPlaceholder(primaryTitle, ''),
    primaryCardTexts: richTextWithPlaceholder(primaryCardTexts, ''),
    secondaryBackgroundImage: secondaryBackgroundImage.gatsbyImageData
      ? {
          image: secondaryBackgroundImage.gatsbyImageData,
          alt: secondaryBackgroundImage.alt ?? '',
        }
      : null,
    secondaryTitle: richTextWithPlaceholder(secondaryTitle, ''),
    secondaryPanelTexts: richTextWithPlaceholder(secondaryPanelTexts, ''),
    tertiaryBackgroundImage: tertiaryBackgroundImage.gatsbyImageData
      ? {
          image: tertiaryBackgroundImage.gatsbyImageData,
          alt: tertiaryBackgroundImage.alt ?? '',
        }
      : null,
    tertiaryTitle: richTextWithPlaceholder(tertiaryTitle, ''),
    tertiaryCardTexts: richTextWithPlaceholder(tertiaryCardTexts, ''),
    quarternaryTexts,
  };
};
