import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useRestaurantsData } from './hooks';
import PrimaryContent from './PrimaryContent';
import SecondaryContent from './SecondaryContent';
import TertiaryContent from './TertiaryContent';
import QuarternaryContent from './QuarternaryContent';
import QuinaryContent from './QuinaryContent';

import { Normalize } from 'utils/types';

export type TRestaurantsSection =
  Normalize<Queries.IndustryDesktopRestaurantsData_PrismicIndustryDataDesktopIndustryRestaurants_Fragment>;

enum RESTAURANTS_TEMPLATE_AREAS {
  primaryContent = 'primary-content',
  secondaryContent = 'secondary-content',
  tertiaryContent = 'tertiary-content',
  quarternaryContent = 'quarternary-content',
}

const Container = styled.div`
  display: grid;
  position: relative;
  align-content: center;
  overflow: hidden;
`;

const BackgroundContainer = styled.div`
  display: grid;
  position: relative;
  grid-template-areas:
    '.'
    '${RESTAURANTS_TEMPLATE_AREAS.primaryContent}'
    '.'
    '${RESTAURANTS_TEMPLATE_AREAS.secondaryContent}'
    '.'
    '${RESTAURANTS_TEMPLATE_AREAS.tertiaryContent}'
    '.'
    '${RESTAURANTS_TEMPLATE_AREAS.quarternaryContent}'
    '.';
  grid-template-rows:
    10.5fr
    auto
    24.75fr
    auto
    19fr
    auto
    27.625fr
    auto
    14.125fr;
  align-content: center;
  margin-bottom: -1px;
  height: 425.125rem;
  overflow: hidden;
`;

const BackgroundImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 140%;
`;

const BackgroundImage = styled(GatsbyImage)`
  height: 100%;
`;

const StyledPrimaryContent = styled(PrimaryContent)`
  grid-area: ${RESTAURANTS_TEMPLATE_AREAS.primaryContent};
`;

const StyledSecondaryContent = styled(SecondaryContent)`
  grid-area: ${RESTAURANTS_TEMPLATE_AREAS.secondaryContent};
`;

const StyledTertiaryContent = styled(TertiaryContent)`
  grid-area: ${RESTAURANTS_TEMPLATE_AREAS.tertiaryContent};
`;

const StyledQuarternaryContent = styled(QuarternaryContent)`
  grid-area: ${RESTAURANTS_TEMPLATE_AREAS.quarternaryContent};
`;

const RestaurantsSection: React.FC<TRestaurantsSection> = sliceData => {
  const {
    backgroundContainerRef,
    backgroundRef,
    backgroundImage,
    primaryTitle,
    primaryCardTexts,
    secondaryTitle,
    secondaryDescription,
    tertiaryTitle,
    tertiaryCardTexts,
    quarternaryTitle,
    quarternaryPanelTexts,
    quinaryTitle,
    quinaryDescription,
  } = useRestaurantsData(sliceData);

  return (
    <Container>
      <BackgroundContainer ref={backgroundContainerRef}>
        {backgroundImage ? (
          <BackgroundImageContainer ref={backgroundRef}>
            <BackgroundImage {...backgroundImage} />
          </BackgroundImageContainer>
        ) : null}
        <StyledPrimaryContent title={primaryTitle} cardTexts={primaryCardTexts} />
        <StyledSecondaryContent title={secondaryTitle} description={secondaryDescription} />
        <StyledTertiaryContent title={tertiaryTitle} cardTexts={tertiaryCardTexts} />
        <StyledQuarternaryContent title={quarternaryTitle} panelTexts={quarternaryPanelTexts} />
      </BackgroundContainer>
      <QuinaryContent title={quinaryTitle} description={quinaryDescription} />
    </Container>
  );
};

export const desktopRestaurantsFragment = graphql`
  fragment IndustryDesktopRestaurantsData on PrismicIndustryDataDesktopSlicesType {
    ... on PrismicIndustryDataDesktopIndustryRestaurants {
      slice_type

      ...IndustryDesktopRestaurantsPrimaryContentData
      ...IndustryDesktopRestaurantsSecondaryContentData
      ...IndustryDesktopRestaurantsTertiaryContentData
      ...IndustryDesktopRestaurantsQuarternaryContentData
      ...IndustryDesktopRestaurantsQuinaryContentData

      primary {
        industry_restaurants_background_image {
          gatsbyImageData
          alt
        }
      }
    }
  }
`;

export default RestaurantsSection;
