import { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';

import { TOfficesSection } from './OfficesSection';

import { richTextWithPlaceholder } from 'utils/prismic';

const useOfficesAnimation = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const context = gsap.context(() => {
      gsap.fromTo(
        backgroundRef.current,
        {
          top: '0%',
        },
        {
          scrollTrigger: {
            trigger: containerRef.current,
            start: 'top top',
            end: `bottom bottom`,
            scrub: true,
          },
          top: '-40%',
        }
      );
    });

    return () => context.revert();
  }, []);

  return { containerRef, backgroundRef };
};

export const useOfficesData = ({ primary }: TOfficesSection) => {
  const {
    industry_offices_background_image: backgroundImage,
    industry_offices_primary_title: primaryTitle,
    industry_offices_primary_panel_texts: primaryPanelTexts,
    industry_offices_secondary_title: secondaryTitle,
    industry_offices_secondary_card_texts: secondaryCardTexts,
    industry_offices_tertiary_title: tertiaryTitle,
    industry_offices_tertiary_panel_texts: tertiaryPanelTexts,
    industry_offices_quarternary_title: quarternaryTitle,
    industry_offices_quarternary_description: quarternaryDescription,
  } = primary;

  const { gatsbyImageData, alt } = backgroundImage;

  return {
    backgroundImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
    primaryTitle: richTextWithPlaceholder(primaryTitle, ''),
    primaryPanelTexts: richTextWithPlaceholder(primaryPanelTexts, ''),
    secondaryTitle: richTextWithPlaceholder(secondaryTitle, ''),
    secondaryCardTexts: richTextWithPlaceholder(secondaryCardTexts, ''),
    tertiaryTitle: richTextWithPlaceholder(tertiaryTitle, ''),
    tertiaryPanelTexts: richTextWithPlaceholder(tertiaryPanelTexts, ''),
    quarternaryTitle: richTextWithPlaceholder(quarternaryTitle, ''),
    quarternaryDescription: richTextWithPlaceholder(quarternaryDescription, ''),
    ...useOfficesAnimation(),
  };
};
