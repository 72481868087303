import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Industry from './Industry';

import { withHead } from 'components/SEO';

export const Head = withHead<Queries.IndustryQuery>('industry');

export const query = graphql`
  query Industry($id: String) {
    industry: prismicIndustry(id: { eq: $id }) {
      ...SEOData
      ...IndustryData
      ...IndustryLayoutData
      _previewable
    }
  }
`;

export type AllIndustriesQuery = Queries.AllIndustriesQuery;

export default withPrismicPreview(Industry);
